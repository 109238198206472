import { Component, OnInit, ViewContainerRef } from '@angular/core';

import { CxSnackbarService } from '@bbraun/cortex/snackbar';
import { RouteChangeService } from '@bbraun/helpex/src/lib/route-change';

import { environment } from '../environments/environment';
import { AzureInsightsHelperService } from './modules/shared/services/azure-insights-helper/azure-insights-helper.service';
import { AuthenticateService } from './modules/shared/services/user/authenticate/authenticate.service';
import { AuthorizeService } from './modules/shared/services/user/authorize/authorize.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  isDevMode = false;

  constructor(
    private readonly authenticateService: AuthenticateService,
    private readonly authorizeService: AuthorizeService,
    private readonly azureInsightsHelper: AzureInsightsHelperService,
    private readonly routeChangeService: RouteChangeService,
    private readonly cxSnackbarService: CxSnackbarService,
    private readonly viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this.isDevMode = environment.develop;
    this.authenticateService.handleUserAuthentication();
    this.authorizeService.handleUserAuthorization();
    this.azureInsightsHelper.handleMonitoring();
    this.cxSnackbarService.setViewContainerRef(this.viewContainerRef);
    this.routeChangeService.routeChange$.subscribe(() => {
      this.cxSnackbarService.clear(undefined, true);
    });
  }
}
